var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.flag == "stars"
    ? _c("div", [
        _vm.fullstars > 0
          ? _c(
              "div",
              { staticClass: "ratings" },
              _vm._l(_vm.fullstars, function(n, index) {
                return _c("i", { staticClass: "fas fa-home" })
              }),
              0
            )
          : _vm._e()
      ])
    : _vm.flag == "ratings"
    ? _c("div", [
        _c("span", [_vm._v("5,0 / 5 hervorragend")]),
        _vm._v(" "),
        _vm.fullstars > 0
          ? _c(
              "div",
              { staticClass: "ratings" },
              _vm._l(_vm.fullstars, function(n, index) {
                return _c("i", { staticClass: "far fa-dot-circle" })
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c("span", [_vm._v(" 1 Bewertung")])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }