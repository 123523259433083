export default {
	data: {
		selectedImageIndex: undefined,
		showAnmelden: true
	},
	beforeMount: function () {
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		},
		bannerVideo: function () {
			var currentDate = new Date();
			var season = 'winter';
			var day = currentDate.getDate();
			var month = currentDate.getMonth() + 1;
			if (month >= 3 && month < 10) {
				if (month == 3 && day >= 15) {
					season = 'summer';
				} else if (month != 3) {
					season = 'summer';
				}

			}
			/*if (season == 'summer') {
				return 'https://www.youtube.com/embed/Az3zp5iB3xM?playlist=Az3zp5iB3xM&loop=1&autoplay=1';
			} else {
				return 'https://www.youtube.com/embed/S-ivNAYxHno?playlist=S-ivNAYxHno&loop=1&autoplay=1';
			}*/
			if (season == 'summer') {
				return 'https://voffice-member-big-files.s3-eu-west-1.amazonaws.com/2855/Sommerfilm.mp4';
			} else {
				return 'https://voffice-member-big-files.s3-eu-west-1.amazonaws.com/2855/Winterfilm.mp4';
			}
		},

		posterVideo: function () {
			var currentDate = new Date();
			var season = 'winter';
			var day = currentDate.getDate();
			var month = currentDate.getMonth() + 1;
			if (month >= 3 && month < 10) {
				if (month == 3 && day >= 15) {
					season = 'summer';
				} else if (month != 3) {
					season = 'summer';
				}

			}
			/*if (season == 'summer') {
				return 'https://www.youtube.com/embed/Az3zp5iB3xM?playlist=Az3zp5iB3xM&loop=1&autoplay=1';
			} else {
				return 'https://www.youtube.com/embed/S-ivNAYxHno?playlist=S-ivNAYxHno&loop=1&autoplay=1';
			}*/
			if (season == 'summer') {
				return 'https://voffice-member-big-files.s3-eu-west-1.amazonaws.com/2855/Sommerfilm.mp4';
			} else {
				return 'https://voffice-member-big-files.s3-eu-west-1.amazonaws.com/2855/Winterfilm.mp4';
			}
		}
	}

};