<template>
    <div v-if="flag=='stars'">
        <div class="ratings"
            v-if="fullstars > 0">
            <i v-for="(n, index) in fullstars"
                class="fas fa-home"></i>

            <!--   <i v-if="halfstar"
                class="fa fa-star-half-o"></i>
            <i v-for="(n, index) in emptystars"
                class="fa fa-star-o"></i>-->
        </div>
    </div>
    <div v-else-if="flag=='ratings'">
        <span>5,0 / 5 hervorragend</span>
        <div class="ratings"
            v-if="fullstars > 0">
            <i v-for="(n, index) in fullstars"
                class="far fa-dot-circle"></i>

            <!--   <i v-if="halfstar"
                class="fa fa-star-half-o"></i>
            <i v-for="(n, index) in emptystars"
                class="fa fa-star-o"></i>-->
        </div>
        <span> 1 Bewertung</span>
    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'star-display',
    	props: {
    		stars: Number,
    		flag: {
    			type: String,
    		}
    	},
    	data: function() {
    		return {
    			totalStars: 5
    		};
    	},
    	computed: {

    		fullstars: function() {
    			return parseInt(this.stars) || 0;
    		},
    		halfstar: function() {
    			var remaingStar = this.stars - parseInt(this.stars);
    			return (remaingStar > 0.49 ? true : false);
    		},
    		emptystars: function() {
    			if (this.halfstar) {
    				return this.totalStars - (parseInt(this.stars) || 0) - 1;
    			} else {
    				return this.totalStars - (parseInt(this.stars) || 0);
    			}
    		}
    	}
    };
</script>